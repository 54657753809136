import React from "react"
import { Link } from "gatsby"
import "@fontsource/newsreader"

export default function Home() {
  return (
    <div class="container full">
      <div class="row">
        <div class="column center">
          <h1>404</h1>
          <h1>¯\_(ツ)_/¯</h1>
          <Link to="/">were you looking for this?</Link>
        </div>
      </div>
    </div>
  )
}
